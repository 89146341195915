import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import moment from "moment";
import { PageWrapper } from "../../../components";
import { ConfirmDialog, EmptyInfo, LoadingMask } from "../../../common";
import { notificationAction } from "../../../actions";
import "./searcherOpportunityDraft.scss";
import {
  getShortlistsApi,
  getShortlistResumesApi,
  getDraftOpportunityDetailApi,
  deleteOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getSavedSearchesApi,
  saveOpportunity,
  getAllLocationsApi,
  getAllLanguagesApi,
  getDraftOpportunitesApi,
} from "../../Opportunity/opportunityApi";
import { getCompaniesApi } from "../../Company/companyApi";
import {
  deleteResumeApi,
  getShortlistResumeApi,
} from "../../Shortlist/shortlistApi";
import { isNullOrUndefined } from "util";
import { onStateChangeAction } from "./searcherOpportunityDraftAction";
import { getCurrenciesApi } from "../../Collaboration/common/collaborationApi";
import { Col, Input, Row, Select, Space, Typography } from "antd";
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";
import NewSearcherOpportunityForm from "./NewCreateOpportunity/component/NewSearcherOpportunityForm";
import ShortListResume from "./NewCreateOpportunity/component/resumeResumeList";
import DeleteIcon from '../../../assets/images/Icons/delete-modal.png'
import DetailModalCreateOpportunity from "./NewCreateOpportunity/component/detailModal";
import UpdateTitleModal from "../../../components/updateTitleModal/updateTitleModal";
import SearchIcon from '../../../assets/images/secrch-icon.png'
import { formatDate } from "../../../utilities";


const { Option } = Select;
const { Title, Text } = Typography

class NewSearcherOpportunityDraft extends Component {
  constructor(props) {
    super(props);

    this.selectedOpportunity = {
      invalidSelectedCompany: false,
      invalidRequestName: false,
      invalidFeeCurrencyType: false,
      invalidStartDate: false,
      invalidDurationType: false,
      invalidSelectedSavedSearch: false,
      Countries: [],
      Description: "",
      Duration: 4,
      DurationType: "",
      FeeCurrencyType: "",
      HourlyFee: "",
      Language: [],
      RequestName: "",
      StartDate: "",
    };
    this.state = {
      isSearchFocus: false,
      searchListHover: false,
      isLoading: false,
      isResumeDetail: false,
      showDetail: false,
      active: "",
      windowWidth: window.innerWidth,
      updateTitleModal: {
        open: false,
      },
    };
  }

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true, shortlistResumes: [] });
    this.setState((st) => ({ ...st, isLoading: true }));
    getDraftOpportunitesApi({ isFreelancer })
      .then((response) => {
        const { success, items } = response;
        if (success) {
          this.setState((st) => ({
            ...st,
            updateTitleModal: {
              ...st.updateTitleModal,
              id: items?.Draft?.[0]?.RequestId,
              value: items?.Draft?.[0]?.RequestName,
            },
          }));
          const opportunities =
            !isEmpty(items) && items.Draft ? items.Draft : [];
          const filterData = items.Draft?.map((single) => {
            return {
              ...single,
              label: single?.RequestName,
              value: single?.RequestId,
            };
          });
          this.props.onStateChangeAction({
            opportunities: filterData,
            filterOpportunires: filterData,
            isFetching: false,
          });
          // if (!isEmpty(filterData)) {
          //   this.handleOpportunityClick({
          //     selectedOpportunity: filterData?.[0],
          //   });
          // }
          this.setState((st) => ({ ...st, isLoading: false }));
          const { onBoardOpportunityId } = this.props;
          if (onBoardOpportunityId) {
            this.props.onStateChangeAction({
              opportunities,
              filteredOpportunity: opportunities.filter(
                (a) => a.RequestId == onBoardOpportunityId
              ),
            });
            this.handleOpportunityClick({
              selectedOpportunity: opportunities.find(
                (a) => a.RequestId == onBoardOpportunityId
              ),
            });
          }
        }
      })
      .catch(() => {
        this.setState((st) => ({ ...st, isLoading: false }));
      });
  };

  getShotlists = () => {
    getShortlistsApi()
      .then((response) => {
        if (response.success) {
          const shortlist = response?.items?.map((item) => ({
            ...item,
            value: item?.ShortlistId,
            label: item?.ShortlistName,
          }));
          this.props.onStateChangeAction({
            shortlists: shortlist,
            filterShortlists: shortlist,
          });
        }
      })
      .catch((response) => response);
  };

  getLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locations = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.props.onStateChangeAction({ locations });
      }
    });
  };
  getLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const languages = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        this.props.onStateChangeAction({ languages });
      }
    });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.getOpportunities();
    this.getShotlists();
    this.getLanguages();
    this.getLocations();
    this.getCurrencies();
    getCompaniesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          userCompanies: map(items, ({ CompanyName, UserCompanyId }) => {
            return { label: CompanyName, value: UserCompanyId };
          }),
        });
      }
    });
    getSavedSearchesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
            return { label: SearchName, value: SavedSearchId };
          }),
        });
      }
    });
    const { onBoardOpportunityId } = this.props;
    if (onBoardOpportunityId) {
      this.getOpportunities();
    }
    if (this?.props?.history?.location?.pathname?.split("/")?.length > 2) {
      this.setState((st) => ({
        ...st,
        updateTitleModal: { open: true, title: "Opportunity" },
      }));
    }
    this.setState(st => ({ ...st, active: window.location.hash?.slice(1) }))
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };


  handleOpportunityClick = ({ selectedOpportunity }) => {
    this.setState((st) => ({ ...st, isLoading: true, showDetail: true }));
    const { RequestId } = selectedOpportunity;
    let selectedShortlist = null;
    this.props.onStateChangeAction({
      isFetchingShortlist: true,
      currentOpportunityId: RequestId,
      isFetchingShortlistResume: true,
      selectedShortlist: [],
      selectedOpportunity: {
        ...this.selectedOpportunity,
        ...selectedOpportunity,
      },
      shortlistResumes: [],
      selectedResume: {},
    });
    getDraftOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const {
            ShortLists,
            UserCompanyId,
            SavedSearchedId,
            DurationType,
            Languages,
            Countries,
            StartDate,
            ...rest
          } = items;
          const UserCompany = filter(
            this.props.userCompanies,
            (company) => company.value === UserCompanyId
          )[0];
          const SavedSearches = filter(
            this.props.savedSearches,
            (search) => search.value === SavedSearchedId
          )[0];
          if (isEmpty(ShortLists)) {
            selectedOpportunity = {
              ...this.props.selectedOpportunity,
              Language: Languages,
              selectedLanguages: Languages?.map((single) => single?.LanguageId),
              selectedLocation: Countries?.map((single) => single?.CountryId),
              DurationType: DurationType || "Weeks",
              Countries,
              StartDate: moment(StartDate),
              FeeCurrencyType: this.props.allCurrenciesList.find(
                (x) => x.CurrencyId == items.FeeCurrencyType
              ),
              ...rest,
            };
            this.setState((st) => ({ ...st, isLoading: false }));
            this.props.onStateChangeAction({
              selectedOpportunity,
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedCompany: UserCompany ? UserCompany : {},
              selectedSaveSearch: SavedSearches ? SavedSearches : {},
              isFetchingShortlist: false,
            });
            return;
          }
          selectedShortlist = head(ShortLists);

          this.props.onStateChangeAction({
            selectedOpportunity: {
              ...this.props.selectedOpportunity,
              Language: Languages,
              selectedLanguages: Languages?.map((single) => single?.LanguageId),
              selectedLocation: Countries?.map((single) => single?.CountryId),
              selectedShortlists: ShortLists?.map(
                (single) => single?.ShortlistId
              ),
              Countries,
              StartDate: moment(StartDate),
              DurationType: DurationType || "Weeks",
              FeeCurrencyType: this.props.allCurrenciesList.find(
                (x) => x.CurrencyId == items.FeeCurrencyType
              ),
              ...rest,
            },

            selectedCompany: UserCompany ? UserCompany : {},
            selectedSaveSearch: SavedSearches ? SavedSearches : {},
            selectedShortlist: ShortLists,
            isFetchingShortlist: false,
          });
          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                if (isEmpty(items)) {
                  const info = {
                    message: this.props.labels
                      .InfoSearcherOpportunityDraftEmptyShortlist,
                    status: "info",
                  };
                  this.props.notificationAction(info);
                  this.setState((st) => ({ ...st, isLoading: false }));
                  return;
                }
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: head(items),
                  isFetchingShortlistResume: false,
                });
              }
              this.setState((st) => ({ ...st, isLoading: false }));
            })
            .catch((response) => {
              this.setState((st) => ({ ...st, isLoading: false }));
            });
        }
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
        });
      });
  };

  handleDateChange = (date) => {
    const { selectedOpportunity } = this.props;
    try {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          invalidStartDate: false,
          StartDate: date,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };


  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filterOpportunires, searchKey: value });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    this.props.onStateChangeAction({
      opportunityToDelete: selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };

  handleYesClick = () => {
    const { yesClickCount, opportunityToDelete } = this.props;
    const { RequestId } = opportunityToDelete;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    this.setState((st) => ({ ...st, isLoading: true }));
    deleteOpportunityAndAssociationsApi({
      RequestId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          this.props.onStateChangeAction({
            isLoading: false,
          });
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
        }
        setTimeout(() => {
          this.deleteOpportunity({ RequestId });
        }, 5000);
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };

  deleteOpportunity = ({ RequestId }) => {
    this.setState((st) => ({ ...st, isLoading: true }));
    deleteOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  handleNoClick = () => {
    const { labels } = this.props;
    const { dialogMessage } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      const {
        opportunityToDelete: { RequestId },
      } = this.props;
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (name === "selectedSavedSearch") {
      this.props.onStateChangeAction({
        selectedSaveSearch: isNullOrUndefined(selectedOption)
          ? null
          : selectedOption,
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedSavedSearch: false,
        },
      });
      return;
    }
    if (!selectedOption) return;
    if (name === "SelectedCompany") {
      this.props.onStateChangeAction({
        selectedCompany: selectedOption,
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedCompany: false,
        },
      });
      return;
    }
    if (name === "FeeCurrencyType") {
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          feeCurrencyType: false,
        },
      }));
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidFeeCurrencyType: false,
          FeeCurrencyType: selectedOption,
        },
      });
      return;
    }
    if (name === "DurationType") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidDurationType: false,
          DurationType: selectedOption,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        [name]: selectedOption,
      },
    });
  };

  handleLanguageSelect = (e, name, option) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        selectedLanguages: e,
        Language: option,
      },
    });
  };

  handleLocationSelect = (e, name, option) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        selectedLocation: e,
        Countries: option,
      },
    });
  };

  handleShortlistSearchChange = (e) => {
    const { value } = e.target;
    const { shortlists } = this.props;
    const filterShortlists = filter(shortlists, (shortlist) =>
      includes(toLower(shortlist.ShortlistName), toLower(value))
    );
    this.props.onStateChangeAction({ filterShortlists });
  };

  handleSelectedShortlistClick = (option) => {
    this.setState((st) => ({ ...st, isLoading: true }));
    const { selectedOpportunity } = this.props;
    getShortlistResumesApi({ ShortlistId: option?.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist: [option],
            selectedOpportunity: {
              ...selectedOpportunity,
              selectedShortlists: option?.ShortlistId,
              [`invalidShortList`]: false,
            },
            shortlistResumes: items,
            selectedResume,
            isFetchingShortlistResume: false,
          });
        }
        this.setState((st) => ({ ...st, isLoading: false }));
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
      });
  };

  handleSelectedShortlistDelete = ({ shortlist, e }) => {
    e.stopPropagation();
    let { selectedShortlists } = this.props;
    selectedShortlists = filter(
      selectedShortlists,
      (item) => item.ShortlistId !== shortlist.ShortlistId
    );
    this.props.onStateChangeAction({
      selectedShortlists,
      shortlistResumes: [],
      selectedResume: {},
    });
  };

  validateField = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "RequestName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyFee") {
      if (isNaN(value)) {
        return;
      }
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          hourlyRate: false,
        },
      }));
    }
    this.setFieldValue({ name, value });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    this.props.onStateChangeAction({ selectedResume });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleSliderChange = (value) => {
    const { selectedOpportunity } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          Duration: value,
        },
      });
    }
  };

  handleCountryDelete = ({ selectedCountry }) => {
    const { selectedOpportunity } = this.props;
    const filterCountries = filter(
      selectedOpportunity.Countries,
      (country) => country.CountryId !== selectedCountry.CountryId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Countries: filterCountries,
      },
    });
  };

  handleLanguageDelete = ({ selectedLanguage }) => {
    const { selectedOpportunity } = this.props;
    const filterLanguages = filter(
      selectedOpportunity.Language,
      (country) => country.LanguageId !== selectedLanguage.LanguageId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Language: filterLanguages,
      },
    });
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        this.props.onStateChangeAction({
          shortlistResumes: data.items,
          fetchingResumes: false,
          selectedResume: head(data.items) || {},
        });
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleOpportunitySend = () => {
    const {
      selectedShortlists,
      selectedShortlist,
      selectedOpportunity,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;

    const {
      RequestName,
      StartDate,
      DurationType,
      HourlyFee,
      FeeCurrencyType,
    } = selectedOpportunity;
    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }
    if (!StartDate) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftStartDateRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "StartDate", value: StartDate });
      return;
    }
    if (!DurationType) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftDurationTypeRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "DurationType", value: DurationType });
      return;
    }
    if (!HourlyFee) {
      const info = {
        message: this.props.labels
          .searcher_phillip_automate_oppertunity_hourlyrate_alert,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          hourlyRate: true,
        },
      }));
      return;
    }
    if (!FeeCurrencyType) {
      const info = {
        message: this.props.labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          feeCurrencyType: true,
        },
      }));
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidFeeCurrencyType`]: !FeeCurrencyType,
          FeeCurrencyType: FeeCurrencyType,
        },
      });
      return;
    }
    if (isEmpty(selectedCompany)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftUserCompanyRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidSelectedCompany`]: true,
          selectedCompany: selectedCompany,
        },
      });
      return;
    }

    if (isEmpty(selectedShortlist)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftSendEmptyShortlistMsg,
        status: "error",
      };
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidShortList`]: true,
        },
      });
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftSendConformationMsg,
    });
  };

  handleOpportunityUpdate = ({ IsSent = false }) => {
    const {
      selectedOpportunity,
      selectedShortlist,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const { invalidRequestName, RequestName } = selectedOpportunity;

    if (!RequestName) {
      this.validateField({ name: "RequestName", value: RequestName });
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    if (IsSent) {
      if (selectedCompany && !selectedCompany.value) {
        const info = {
          message: this.props.labels
            .InfoSearcherOpportunityDraftCompanyRequiredMsg,
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          selectedOpportunity: {
            ...selectedOpportunity,
            invalidSelectedCompany: true,
          },
        });
        return;
      }
    }

    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlist,
      (shortlist) => shortlist.ShortlistId
    );

    const opportunity = {
      Countries: [],
      CreateDate: selectedOpportunity.CreateDate,
      Description: selectedOpportunity.Description,
      Duration: selectedOpportunity.Duration,
      FeeCurrencyType: selectedOpportunity.FeeCurrencyType
        ? typeof selectedOpportunity.FeeCurrencyType === "string"
          ? selectedOpportunity.FeeCurrencyType
          : selectedOpportunity.FeeCurrencyType.value
        : null,
      DurationType: selectedOpportunity.DurationType
        ? typeof selectedOpportunity.DurationType === "string"
          ? selectedOpportunity.DurationType
          : selectedOpportunity.DurationType.value
        : null,
      EndDate: selectedOpportunity.EndDate,
      HourlyFee: selectedOpportunity.HourlyFee,
      IsSent,
      Language: [],
      LanguageIds,
      LocationsIds,
      RequestCountries: null,
      RequestId: selectedOpportunity.RequestId,
      RequestName: selectedOpportunity.RequestName,
      SavedSearchedId: selectedSaveSearch ? selectedSaveSearch.value : null,
      ShortListIds,
      StartDate: selectedOpportunity.StartDate,
      UserCompanyId: selectedCompany ? selectedCompany.value : null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    saveOpportunity({ opportunity }).then((response) => {
      if (response.success) {
        if (IsSent) {
          this.props.onStateChangeAction({ selectedOpportunity: null });
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSendSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSavedSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
        }
        this.getOpportunities();
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        return;
      }

      const info = {
        message: response.message,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({ opportunitySaveLoading: false });
    });
  };
  handleModalClick = (data) => {
    this.setState((st) => ({
      ...st,
      data: data,
      detailModal: true,
    }));
  };

  handleCloseModal = () => {
    this.setState((st) => ({
      ...st,
      data: "",
      detailModal: false,
    }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, isResumeDetail: true }));
  };
  handleUpdateTitle = () => {
    const opportunity = {
      RequestId: this.state.updateTitleModal.id,
      RequestName: this.state.updateTitleModal.value,
    };
    saveOpportunity({ opportunity })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSavedUpdatedSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.setState((st) => ({ ...st, updateTitleModal: { open: false } }));
          return;
        }
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
        this.setState((st) => ({ ...st, updateTitleModal: { open: false } }));
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  updateTitle = (e) => {
    if (typeof e == "object") {
      this.setState((st) => ({ ...st, updateTitleModal: { open: false } }));
    } else {
      this.setState((st) => ({
        ...st,
        updateTitleModal: { ...st.updateTitleModal, value: e()?.value },
      }));
    }
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      dialogMessage,
      filterOpportunires,
      selectedOpportunity,
      filterShortlists,
      shortlistResumes,
      selectedSaveSearch,
      selectedCompany,
      savedSearches,
      userCompanies,
      languages,
      locations,
      opportunitySaveLoading,
      allCurrenciesList,
    } = this.props;


    const getTabs = () => {

      return [
        {
          id: 1,
          label: labels?.IPRO_NAVIGATION_MESSAGE_SENT_LABEL,
          active: window?.location?.hash == "#/sent-opportunities",
          value: "/sent-opportunities"
        },
        {
          id: 2,
          label: labels?.SEARCHER_NAVIGATION_OPPORTUNITY_DRAFT_LABEL,
          active: window?.location?.hash == "#/draft-opportunities",
          value: "/draft-opportunities"
        },
      ]
    }

    return (
      <PageWrapper className="searcher-draft-opportunity-page search-page-new-design">
        {this?.state?.isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
                labels.InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  testId="searcher-opportunity-draft-ok-btn"
                  onClick={this.handleOkClick}
                >
                  Yes
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  testId="searcher-opportunity-draft-yes-btn"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                testId="searcher-opportunity-draft-no-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {this.state?.detailModal == true && (
          <DetailModalCreateOpportunity
            handleCloseModal={this.handleCloseModal}
            open={this.state}
            selected={this.state?.selected}
          />
        )}
        <UpdateTitleModal
          open={this?.state?.updateTitleModal}
          setOpen={this.updateTitle}
          handleUpdateTitle={this.handleUpdateTitle}
        />
        <div className="h-100 new-design-search w-100 d-flex flex-column">
          <div className="tabs-header-col">
            {this?.state?.isMobileDevice ? <div
              onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}
            >
              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
            </div> :
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {this.state.windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        this.setState(st => ({ ...st, active: e }))
                        this.props.history.push(`${e}`)
                      }}
                      value={this?.state?.active}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className="tab-btn-coll"
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            this.props.history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>
                {(this?.state?.windowWidth > 480) && (
                  <button className="tab-btn-coll"
                    style={{ background: "#6C63FF", color: "#fff" }}
                    onClick={() => { this.props.history.push("/create-opportunity") }}
                  >
                    {labels?.create_opportunity_btn}
                  </button>
                )}
              </div>
            }
          </div>
          <Row className=" h-100 " style={{ overflow: "auto" }}>
            <Col xs={24} md={Object.keys(filterOpportunires).length == 0 ? 24 : 14} className="opportunity-column1">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px",
                    borderBottom: "1px solid #F3F3F3"
                  }}
                >
                  {this?.state?.showDetail ?
                    <>
                      {this?.state?.isResumeDetail ?
                        <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                        >
                          <div className="d-flex text-clamp align-items-center gap-8 pointer pointer"
                            onClick={() => { this.setState(st => ({ ...st, isResumeDetail: false })) }}
                          >
                            <div>
                              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                            </div>
                            <Title level={5} className="m-0 text-clamp">{`Selected Resumes (${shortlistResumes?.length || 0})` || ""}</Title>

                          </div>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                        >
                          <div className="d-flex align-items-center gap-8 pointer pointer"
                            onClick={() => { this.setState(st => ({ ...st, showDetail: false })) }}
                          >
                            <div>
                              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                            </div>
                            <Title level={5} className="m-0"> Draft Opportunity</Title>
                          </div>
                          <Text level={5} className="m-0 pointer" style={{ color: "#FF6363" }}
                            onClick={() => {
                              this.handleOpportunityDelete({
                                selectedOpportunity: selectedOpportunity,
                              });
                            }}
                          >Delete Draft</Text>
                        </div>
                      }
                    </>
                    :
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%"
                      }}
                    >

                      <Input
                        placeholder={labels?.SearcherOpportunityDraftSearchPlaceholder}
                        size="medium"
                        bordered={false}
                        style={{ border: "1px solid #F3F3F3" }}
                        onChange={this.handleSearchChange}
                      />
                      <div>
                        <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                      </div>
                    </div>

                  }
                </div>
                {this?.state?.showDetail ? (
                  <>
                    {this?.state?.isResumeDetail ?
                      <div className=" resume-list h-100" style={{ background: "#fff", borderRadius: "12px" }}>
                        <ShortListResume
                          resumeList={shortlistResumes}
                          score={0}
                          handleModalClick={this.handleModalClick}
                          name="opportunity"
                        />
                      </div> :
                      <NewSearcherOpportunityForm
                        state={this.state}
                        shortlist={filterShortlists}
                        handleSelectedShortlistClick={
                          this.handleSelectedShortlistClick
                        }
                        selectedOpportunity={selectedOpportunity}
                        selectedCompany={selectedCompany}
                        selectedSaveSearch={selectedSaveSearch}
                        userCompanies={userCompanies}
                        savedSearches={savedSearches}
                        languages={languages}
                        labels={labels}
                        isLoading={opportunitySaveLoading}
                        allLocations={locations}
                        allLanguages={languages}
                        onFormSelectChange={this.handleFormSelectChange}
                        onFormFieldChange={this.handleFormFieldChange}
                        onDateChange={this.handleDateChange}
                        onSliderChange={this.handleSliderChange}
                        onLanguageSelect={this.handleLanguageSelect}
                        onLocationSelect={this.handleLocationSelect}
                        onOpportunityUpdate={this.handleOpportunityUpdate}
                        onOpportunitySend={this.handleOpportunitySend}
                        allCurrencies={allCurrenciesList}
                        handleListOpenMobile={this.handleListOpenMobile}
                      />
                    }
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        overflow: "auto",
                        padding: "12px",
                        flex: 1
                      }}
                    >
                      {filterOpportunires?.length > 0 ? (
                        <Space size={[6, 6]} wrap className="short-list-new">
                          {filterOpportunires?.map((item) => (
                            <div
                              onClick={() => this.handleOpportunityClick({ selectedOpportunity: item })}
                              key={item?.UserCompanyId}
                              className={`d-flex gap-8 justify-content-between align-items-center pointer ${selectedOpportunity?.RequestId === item?.RequestId
                                ? "background-shortlist short-list-item "
                                : "short-list-item"
                                }`}>
                              <div className="d-flex gap-8">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    {item.label}
                                  </label>
                                  <p style={{ fontSize: "12px", marginBottom: 0 }}>{formatDate(item?.UpdatedOn)}</p>
                                </div>
                              </div>
                              {selectedOpportunity?.RequestId === item?.RequestId &&
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleOpportunityDelete({
                                      selectedOpportunity: item,
                                    });
                                  }}
                                >
                                  <img src={DeleteIcon} alt="" style={{ height: "20px" }} />
                                </div>
                              }
                            </div>
                          ))}
                        </Space>
                      ) : (
                        <EmptyInfo>{labels?.emptyOpportunityInfo}</EmptyInfo>
                      )}
                    </div>
                  </>
                )}

                {(this?.state?.windowWidth < 480 && !this?.state?.showDetail) && (
                  <div style={{ margin: "auto", padding: "12px" }}>
                    <div className="tabs-header-col my-0">
                      <button className="tab-btn-coll"
                        style={{ background: "#6C63FF", color: "#fff" }}
                        onClick={() => { this.props.history.push("/create-opportunity") }}
                      >
                        {labels?.create_opportunity_btn}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col md={10} xs={0} offset={0.5}
              className={
                Object.keys(filterOpportunires).length == 0 ? null : "h-100 "
              }
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <Title level={5} className="m-0 text-clamp" style={{ padding: "12px", borderBottom: "1px solid #f4f2fe" }}>{selectedOpportunity?.RequestId ? `Selected Resumes (${shortlistResumes?.length || 0})` : "Select Opportunity"}
                </Title>
                <ShortListResume
                  resumeList={shortlistResumes}
                  score={0}
                  handleModalClick={this.handleModalClick}
                  name="opportunity"
                  label="No Resume found"
                />
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunityDraft,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer, UserId } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  const userId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    userId,
    isHelpActive,
    ...searcherOpportunityDraft,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(NewSearcherOpportunityDraft);

import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, head, first } from "lodash";
import { PageWrapper } from "../../../../components";
import last from "lodash/last";
import { ConfirmDialog, LoadingMask } from "../../../../common";
import { notificationAction } from "../../../../actions";
import "../searcherOpportunityDraft.scss";
import {
  getShortlistsApi,
  getShortlistResumesApi,
  getOpportunityApi,
  deleteOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getSavedSearchesApi,
  saveOpportunity,
  sendOpportunityApi,
  getAllLocationsApi,
  getAllLanguagesApi,
  getDraftOpportunityDetailApi,
} from "../../opportunityApi";
import moment from "moment";
import { getCompaniesApi } from "../../../Company/companyApi";
import { isNullOrUndefined } from "util";
import { onStateChangeAction } from "../createNewOpportunityAction";
import { getCurrenciesApi } from "../../../Collaboration/common/collaborationApi";
import { Col, Row, Typography, Input, Select } from "antd";
import NewSearcherOpportunityForm from "./component/NewSearcherOpportunityForm";
import ShortListResume from "./component/resumeResumeList";
import DetailModalCreateOpportunity from "./component/detailModal";
import ArrowLeft from "../../../../assets/images/Icons/arrow-left.png";
const { Title } = Typography
class CreateNewOpportunity extends Component {
  state = {
    detailModal: false,
    windowWidth: window?.innerWidth,
    active: null,
    isResumeDetail: false,
    validation: {},
  };

  getShortlists = () => {
    getShortlistsApi()
      .then((response) => {
        const allShortList = response?.items?.map((item) => ({
          ...item,
          value: item?.ShortlistId,
          label: item?.ShortlistName,
        }));
        if (response.success) {
          this.props.onStateChangeAction({
            shortlists: allShortList,
            filterShortlists: allShortList,
          });
        }
        if (
          sessionStorage.getItem("opportunity-new") ||
          sessionStorage.getItem("opportunity")
        ) {
          const selectedItem = first(allShortList);
          this.handleSelectedShortlistClick(selectedItem);
        }
      })
      .catch((response) => response);
  };
  getCompanies = () => {
    getCompaniesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          userCompanies: map(items, ({ CompanyName, UserCompanyId }) => {
            return { label: CompanyName, value: UserCompanyId };
          }),
          selectedCompany:
            items.length > 0
              ? { label: items[0].CompanyName, value: items[0].UserCompanyId }
              : {},
        });
      }
    });
  };
  getSavedSearches = () => {
    getSavedSearchesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        const lastItem = last(response.items);

        if (
          sessionStorage.getItem("opportunity-new") ||
          sessionStorage.getItem("opportunity")
        ) {
          this.props.onStateChangeAction({
            savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
              return { label: SearchName, value: SavedSearchId };
            }),
            selectedSaveSearch:
              items.length > 0
                ? {
                  label: lastItem.SearchName,
                  value: lastItem.SavedSearchId,
                }
                : {},
          });
        } else {
          this.props.onStateChangeAction({
            savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
              return { label: SearchName, value: SavedSearchId };
            }),
            // selectedSaveSearch:
            //   items.length > 0
            //     ? { label: items[0].SearchName, value: items[0].SavedSearchId }
            //     : {},
          });
        }
      }
    });
  };
  getAllLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const allLocationsList = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.props.onStateChangeAction({ allLocationsList });
      }
    });
  };
  getAllLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const allLanguagesList = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        this.props.onStateChangeAction({ allLanguagesList });
      }
    });
  };

  setRequestName = () => {
    if (
      sessionStorage.getItem("opportunity-new") ||
      sessionStorage.getItem("opportunity")
    ) {
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          RequestName: `Quick Opportunity at ${moment(new Date()).format(
            "D MMMM YYYY"
          )} at ${moment(new Date()).format("LTS")}`,
        },
      });
    }
  };

  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));

          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    this.getCurrencies();
    this.setRequestName();
    this.getShortlists();
    this.getCompanies();
    this.getSavedSearches();
    this.getAllLocations();
    this.getAllLanguages();
    const selectedOpp = sessionStorage.getItem("opportunity");
    if (selectedOpp) {
      const selectedOpportunity = JSON.parse(selectedOpp);
      this.handleOpportunityClick({ selectedOpportunity });
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    sessionStorage.removeItem("opportunity-new");
    sessionStorage.removeItem("opportunity");
    sessionStorage.removeItem("shortList");
    window.removeEventListener("resize", this.handleResize);
  }

  handleOpportunityClick = ({ selectedOpportunity }) => {
    const { RequestId } = selectedOpportunity;
    this.props.onStateChangeAction({
      isFetchingShortlist: true,
    });
    getDraftOpportunityDetailApi({ RequestId }).then((response) => {
      if (response.success) {
        const { items } = response;
        const {
          ShortLists,
          UserCompanyId,
          SavedSearchedId,
          Languages,
          DurationType,
          Countries,
          StartDate,
          FeeCurrencyType,
          ...rest
        } = items;
        const UserCompany = filter(
          this.props.userCompanies,
          (company) => company.value === UserCompanyId
        )[0];
        const SavedSearches = filter(
          this.props.savedSearches,
          (search) => search.value === SavedSearchedId
        )[0];

        this.props.onStateChangeAction({
          selectedOpportunity: {
            ...this.props.selectedOpportunity,
            selectedLanguages: Languages?.map((single) => single?.LanguageId),
            DurationType: DurationType,
            StartDate: StartDate,
            FeeCurrencyType: FeeCurrencyType,
            selectedShortlists: ShortLists?.[0]?.ShortlistId,
            selectedLocation: Countries?.map((single) => single?.CountryId),
            ...rest,
          },

          selectedCompany: UserCompany ? UserCompany : {},
          selectedSaveSearch: SavedSearches ? SavedSearches : {},
          isFetchingShortlist: false,
        });
        if (ShortLists?.length) {
          this.handleSelectedShortlistClick(ShortLists?.[0]);
        }
      }
    });
  };
  handleDateChange = (date) => {
    const { selectedOpportunity } = this.props;
    try {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          invalidStartDate: false,
          StartDate: date,
        },
      });
    } catch (e) { }
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.props.onStateChangeAction({
      selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };

  handleYesClick = () => {
    const {
      yesClickCount,
      selectedOpportunity: { RequestId },
    } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
    });

    this.props.onStateChangeAction({ isLoading: true });
    getOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const { ShortLists, SavedSearchedId } = items;
          if (isEmpty(ShortLists)) {
            deleteOpportunityApi({ RequestId })
              .then((response) => {
                if (response.success) {
                  this.props.onStateChangeAction({ isLoading: false });
                }
              })
              .catch((response) => {
                this.props.onStateChangeAction({ isLoading: false });
              });
            return;
          }
          deleteOpportunityAndAssociationsApi({
            RequestId,
          })
            .then((response) => {
              if (response.success) {
                this.props.onStateChangeAction({ isLoading: false });
              }
            })
            .catch((response) => {
              this.props.onStateChangeAction({ isLoading: false });
            });
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (name === "selectedSavedSearch") {
      this.props.onStateChangeAction({
        selectedSaveSearch: isNullOrUndefined(selectedOption)
          ? null
          : selectedOption,
      });
      return;
    }
    if (!selectedOption) return;
    if (name === "SelectedCompany") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedCompany: false,
        },
        selectedCompany: selectedOption,
      });
      return;
    }
    const { selectedOpportunity } = this.props;
    if (name === "DurationType" || name === "FeeCurrencyType") {
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          feeCurrencyType: false,
        },
      }));
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [name]: selectedOption,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        [name]: selectedOption,
      },
    });
  };

  handleLanguageSelect = (e, name, option) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        selectedLanguages: e,
        Language: option,
      },
    });
  };

  handleLocationSelect = (e, name, option) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        selectedLocation: e,
        Countries: option,
      },
    });
  };

  handleSelectedShortlistClick = (option) => {
    this.props.onStateChangeAction({ isLoading: true });
    const { selectedOpportunity } = this.props;
    getShortlistResumesApi({ ShortlistId: option?.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlists: [option],
            shortlistResumes: items,
            selectedResume,
            isLoading: false,
            selectedOpportunity: {
              ...selectedOpportunity,
              selectedShortlists: option?.ShortlistId,
              [`invalidShortList`]: false,
            },
          });
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
        });
      });
  };

  validateField = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "RequestName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyFee") {
      if (isNaN(value)) {
        return;
      }
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          hourlyRate: false,
        },
      }));
    }
    this.setFieldValue({ name, value });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    this.props.onStateChangeAction({ selectedResume });
  };

  handleSliderChange = (value) => {
    const { selectedOpportunity } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          Duration: value,
        },
      });
    }
  };

  makeMandatoryFieldRed = () => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        invalidRequestName: !undefined,
        RequestName: undefined,
        invalidStartDate: !undefined,
        StartDate: undefined,
        invalidSelectedCompany: !undefined,
        selectedCompany: undefined,
        invalidShortList: !undefined,
        shortlists: undefined,
      },
    });
    if (!selectedOpportunity?.HourlyFee) {
      this.setState((st) => ({
        ...st,
        validation: { ...st.validation, hourlyRate: true },
      }));
    }
    if (!selectedOpportunity?.feeCurrencyType) {
      this.setState((st) => ({
        ...st,
        validation: { ...st.validation, feeCurrencyType: true },
      }));
    }
  };

  handleOpportunitySend = ({ IsSent }) => {
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
    } = this.props;
    const {
      RequestName,
      StartDate,
      FeeCurrencyType,
      HourlyFee,
    } = selectedOpportunity;
    if (!RequestName && !StartDate) {
      const info = {
        message: this.props.labels.OPPORTUNITY_CREATE_REQURIED_FIELD_MESSAGE,
        status: "error",
      };
      this.makeMandatoryFieldRed();
      this.props.notificationAction(info);
      return;
    }

    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }
    if (!HourlyFee) {
      const info = {
        message: this.props.labels
          .searcher_phillip_automate_oppertunity_hourlyrate_alert,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState((st) => ({
        ...st,
        validation: { ...st.validation, hourlyRate: true },
      }));
      return;
    }
    if (!StartDate) {
      const info = {
        message: this.props.labels.collStartDateRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "StartDate", value: StartDate });
      return;
    }
    if (!FeeCurrencyType) {
      const info = {
        message: this.props.labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.setState((st) => ({
        ...st,
        validation: { ...st.validation, feeCurrencyType: true },
      }));
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidFeeCurrencyType`]: !FeeCurrencyType,
          FeeCurrencyType: FeeCurrencyType,
        },
      });
      return;
    }
    if (isEmpty(selectedCompany)) {
      const info = {
        message: this.props.labels.oppCompanyValidationMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidSelectedCompany`]: true,
          selectedCompany: selectedCompany,
        },
      });
      this.validateField({ name: "selectedCompany", value: selectedCompany });
      return;
    }
    if (isEmpty(selectedShortlists)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftSendEmptyShortlistMsg,
        status: "error",
      };
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidShortList`]: true,
        },
      });
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftSendConformationMsg,
    });
  };

  handleOkClick = () => {
    let IsSent = true;
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate,
      FeeCurrencyType,
      DurationType,
    } = selectedOpportunity;
    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId
    );
    const opportunity = {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate: StartDate,
      FeeCurrencyType: FeeCurrencyType?.value,
      DurationType: DurationType || "",
      IsSent,
      LanguageIds,
      LocationsIds,
      ShortListIds,
      UserCompanyId: selectedCompany.value,
      SavedSearchedId: selectedSaveSearch && selectedSaveSearch.value,
      EndDate: StartDate,
      IsSent,
      RequestCountries: null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    sendOpportunityApi({ opportunity }).then((response) => {
      if (response.success) {
        const info = {
          message: this.props.labels.InfoSearcherOpportunityCreateSent,
          status: "success",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        this.props.onStateChangeAction({
          selectedOpportunity: {
            invalidRequestName: "",
            invalidStartDate: "",
            Countries: [],
            Language: [],
            selectedSavedSearch: null,
            FeeCurrencyType: "",
            DurationType: "Weeks",
            Duration: "4",
            invalidDurationType: "",
            invalidCompanyId: "",
          },
          selectedShortlists: [],
          shortlistResumes: [],
          selectedResume: {},
          selectedSaveSearch: {},
        });
        return;
      }
      const info = {
        message: response.message,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({ opportunitySaveLoading: false });
    });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleOpportunityUpdate = ({ IsSent = false }) => {
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate,
      FeeCurrencyType,
      DurationType,
    } = selectedOpportunity;

    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }

    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId
    );

    const opportunity = {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate: StartDate,
      FeeCurrencyType: FeeCurrencyType?.value,
      DurationType: DurationType || "",
      IsSent,
      LanguageIds,
      LocationsIds,
      ShortListIds,
      UserCompanyId: selectedCompany && selectedCompany.value,
      SavedSearchedId: selectedSaveSearch && selectedSaveSearch.value,
      EndDate: StartDate,
      IsSent: false,
      RequestCountries: null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    saveOpportunity({ opportunity }).then((response) => {
      if (response.success) {
        const info = {
          message: this.props.labels
            .InfoSearcherOpportunityDraftSavedSuccessfullyMsg,
          status: "success",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        this.props.onStateChangeAction({
          selectedOpportunity: {
            invalidRequestName: "",
            invalidStartDate: "",
            Countries: [],
            Language: [],
            selectedSavedSearch: "",
            FeeCurrencyType: "",
            DurationType: "Weeks",
            Duration: "4",
            invalidDurationType: "",
            invalidCompanyId: "",
          },
          selectedSaveSearch: {},
          selectedShortlists: [],
          shortlistResumes: [],
          selectedResume: {},
        });
      }
    });
  };

  handleModalClick = (data) => {
    this.setState((st) => ({
      ...st,
      data: data,
      detailModal: true,
    }));
  };

  handleCloseModal = () => {
    this.setState((st) => ({
      ...st,
      data: "",
      detailModal: false,
    }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, isResumeDetail: true }));
  };

  render() {
    const { labels } = this.props;
    const {
      dialogMessage,
      selectedOpportunity,
      shortlistResumes,
      selectedSaveSearch,
      selectedCompany,
      savedSearches,
      userCompanies,
      languages,
      opportunitySaveLoading,
      allLocationsList,
      allLanguagesList,
      shortlists,
      allCurrenciesList,
      isLoading,
    } = this.props;

    const handleMobileModalClose = () => {
      this.setState((st) => ({
        ...st,
        isResumeDetail: false,
      }));
    }; const getTabs = () => {

      return [
        {
          id: 1,
          label: labels?.IPRO_NAVIGATION_MESSAGE_SENT_LABEL,
          active: window?.location?.hash == "#/sent-opportunities",
          value: "/sent-opportunities"
        },
        {
          id: 2,
          label: labels?.SEARCHER_NAVIGATION_OPPORTUNITY_DRAFT_LABEL,
          active: window?.location?.hash == "#/draft-opportunities",
          value: "/draft-opportunities"
        },
      ]
    }
    return (
      <PageWrapper className="searcher-draft-opportunity-page search-page-new-design">
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
                this.props.labels
                  .InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-ok"
                  onClick={this.handleOkClick}
                >
                  Yes
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-yes"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {this.state?.detailModal == true && (
          <DetailModalCreateOpportunity
            handleCloseModal={this.handleCloseModal}
            open={this.state}
            selected={this.state?.selected}
          />
        )}
        {isLoading && <LoadingMask />}
        <div className="h-100 new-design-search w-100 d-flex flex-column">
          <div className="tabs-header-col">
            {this?.state?.isMobileDevice ? <div
              onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}
            >
              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
            </div> :
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {this.state.windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        this.setState(st => ({ ...st, active: e }))
                        this.props.history.push(`${e}`)
                      }}
                      value={this?.state?.active && this?.state?.active}
                      placeholder={labels?.select_opportunity}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className="tab-btn-coll"
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            this.props.history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>
                {(this?.state?.windowWidth > 480) && (
                  <button className="tab-btn-coll"
                    style={{ background: "#6C63FF", color: "#fff" }}
                    onClick={() => { this.props.history.push("/create-opportunity") }}
                  >
                    {labels?.create_opportunity_btn}
                  </button>
                )}
              </div>
            }
          </div>
          <Row className=" h-100 " style={{ overflow: "auto" }}>
            <Col xs={24} md={14} className="opportunity-column1">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px",
                    borderBottom: "1px solid #F3F3F3"
                  }}
                >

                  {this?.state?.isResumeDetail ?
                    <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                    >
                      <div className="d-flex text-clamp align-items-center gap-8 pointer pointer"
                        onClick={() => { this.setState(st => ({ ...st, isResumeDetail: false })) }}
                      >
                        <div>
                          <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                        </div>
                        <Title level={5} className="m-0 text-clamp">{`Selected Resumes (${shortlistResumes?.length || 0})` || ""}</Title>

                      </div>
                    </div>
                    :
                    <Title level={5} className="m-0 text-clamp">{labels?.create_opportunity_btn}</Title>
                  }
                </div>
                {this?.state?.isResumeDetail ?
                  <ShortListResume
                    resumeList={shortlistResumes}
                    score={0}
                    handleModalClick={this.handleModalClick}
                    name="opportunity"
                    label="No Resume found"
                  />
                  :
                  <NewSearcherOpportunityForm
                    state={this.state}
                    shortlist={shortlists}
                    handleSelectedShortlistClick={this.handleSelectedShortlistClick}
                    selectedOpportunity={selectedOpportunity}
                    selectedCompany={selectedCompany}
                    selectedSaveSearch={selectedSaveSearch}
                    userCompanies={userCompanies}
                    savedSearches={savedSearches}
                    languages={languages}
                    labels={labels}
                    isLoading={opportunitySaveLoading}
                    allLocations={allLocationsList}
                    allLanguages={allLanguagesList}
                    onFormSelectChange={this.handleFormSelectChange}
                    onFormFieldChange={this.handleFormFieldChange}
                    onDateChange={this.handleDateChange}
                    onSliderChange={this.handleSliderChange}
                    onLanguageSelect={this.handleLanguageSelect}
                    onLocationSelect={this.handleLocationSelect}
                    onOpportunityUpdate={this.handleOpportunityUpdate}
                    onOpportunitySend={this.handleOpportunitySend}
                    allCurrencies={allCurrenciesList}
                    handleListOpenMobile={this.handleListOpenMobile}
                  />
                }
              </div>
            </Col>
            <Col md={10} xs={0} offset={0.5}
              className="h-100"
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <Title level={5} className="m-0 text-clamp" style={{ padding: "12px", borderBottom: "1px solid #f4f2fe" }}>{selectedOpportunity?.RequestId ? `Selected Resumes (${shortlistResumes?.length || 0})` : "Select Opportunity"}
                </Title>
                <ShortListResume
                  resumeList={shortlistResumes}
                  score={0}
                  handleModalClick={this.handleModalClick}
                  name="opportunity"
                  label="No Resume found"
                />
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  createNewOpportunity,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    isHelpActive,
    ...createNewOpportunity,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(CreateNewOpportunity);
